<template>
  <div>

    <h2>My Child At Play</h2>
    <div class="w-full">
      <div class="carousel-gallery">
        <!-- swiper -->
        <swiper  class="px-20" :options="swiperOption" :dir="$vs.rtl ? 'rtl' : 'ltr'" :key="$vs.rtl">
          <div class="swiper-button-prev" slot="button-prev"></div>

          <swiper-slide v-for="(photo, index) in media" :key="index" class="ph-img-list">
            <img class="responsive h-64 object-cover " :src="photo.src || photo.thumb" :alt="photo.activity"
                 @click="openGallery(index)">
            <span @click="openGallery(index)" class="mt-5 block">{{ photo.activity }}</span>
          </swiper-slide>

<!--          <swiper-slide v-for="(activity, key) in activities" :key="key">
            <img class="responsive h-64 object-cover " :src="activity.photos[0].photo" :alt="activity.name"
                 @click="selectGallery(activity)">
            <span @click="selectGallery(activity)" class="mt-5 block">{{ activity.name }}</span>
          </swiper-slide>-->

          <div class="swiper-button-next" slot="button-next"></div>
        </swiper>

      </div>

      <LightBox
        ref="lightbox"
        :media="media"
        :show-light-box="false"
        :show-caption="false"
        :show-thumbs="false"
      />

    </div>
  </div>
</template>
<script>
import 'swiper/dist/css/swiper.min.css'
import {swiper, swiperSlide} from 'vue-awesome-swiper'


import LightBox from "vue-image-lightbox";
import "vue-image-lightbox/dist/vue-image-lightbox.min.css";



import {mapActions} from "vuex";

export default {
  data() {
    return {
      activities: [],
      swiperOption: {
        slidesPerView: 5,
        spaceBetween: 50,
        // init: false,
        pagination: {
          el: '.swiper-pagination',
          clickable: true
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        },
        breakpoints: {
          1024: {
            slidesPerView: 3,
            spaceBetween: 40
          },
          768: {
            slidesPerView: 2,
            spaceBetween: 30
          },
          640: {
            slidesPerView: 1,
            spaceBetween: 20
          }
        }
      },
      media: [],
    }
  },
  components: {
    swiper,
    swiperSlide,
    LightBox

  },
  methods: {
    ...mapActions("activity", [
      "fetchTaggedActivityByChildId",
      "setActivityForParent"
    ]),
    openGallery(index) {
      this.$refs.lightbox.showImage(index);
      this.currentImageIndex = index;
    },
    async selectGallery(activity) {
      await this.setActivityForParent(activity).then((res) => {
        this.$emit('activitySelected');
      })
    },
    async getSlider(childId) {
      await this.fetchTaggedActivityByChildId(childId).then((response) => {
        this.activities = response.data.data;

        for (const activity of this.activities) {
          for (const photo of activity.photos) {
           let data =  {
              id: photo._id,
              activity: activity.name,
              thumb: photo.photo,
              src: photo.photo,
              srcset: photo.photo,
            };
            this.media.push(data);
          }
        }
      }).catch(error => console.log(error));
    }
  },
  mounted() {
    this.getSlider(this.childId);
  },
  computed: {
    parent() {
      return this.$store.state.AppActiveUser;
    },
    childId() {
      return this.$route.params.childId;
    }
  },
}
</script>
